<template>
  <select>
    <slot></slot>
  </select>
</template>

<script>
export default {
  props: ["options", "modelValue"],
  template: "#select2-template",
  data: () => {
    return{
      el: null
    }    
  },
  mounted: function() {
    var vm = this;
    this.el = $(this.$el);    
    this.el.select2({ data: this.options, width: '100%'})
      .val(this.modelValue)
      .trigger("change")
      .on("change", function() {
        vm.$emit("update:modelValue", this.value);
      });
  },
  watch: {
    modelValue: function(value) {
      // update value
      this.el
        .val(null)
        .trigger("change");
      this.$emit("change", value);
    },
    options: function(options) {
      // update options      
      this.el.empty().select2({ data: options, width: '100%' });
      this.open();
    }
  },
  unmounted: function() {
    this.el.off().select2("destroy");
  },
  methods:{
    open(){
      // console.log('auto open');
      this.el.select2('open');
    }
  }
};

</script>