import { createWebHistory, createRouter } from "vue-router";
import Main from '../views/Main.vue'
import Settings from '../views/Settings.vue'
import Invoice from '../views/Invoice.vue'
import Login from '../views/Login.vue'

const routes = [
  {
    path: "/",
    name: "Main",
    component: Main,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
  },
  {
    path: "/invoice",
    name: "Invoice",
    component: Invoice,
    props: true
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;