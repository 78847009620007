<template>
    <main class="container">
        <div class="text-end card-body">
            <button class="btn btn-outline-info" v-on:click="print">Print</button>
        </div>
        
        <div class="card m-3 invoice">
            <div class="card-body">
                <div class="row">
                    <div class="col">
                        <img alt="" class="invoice-logo" src="/img/logo.3b0bb3eb.png">
                    </div>
                    <div class="col text-end">
                        <h3>INVOICE</h3>
                        <div>Order No #: <strong>{{orderNo}}</strong></div>
                        <div>Date : <strong>{{new Date().toDateString()}}</strong></div>
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col">
                        <h5>COMPANY</h5>

                        {{shopName}} <br>
                        {{addressLine1}} <br>
                        {{addressLine2}} <br>
                    </div>
                    <div class="col text-end">
                        <h5>CUSTOMER</h5>

                        {{customerNo}} <br>
                        <!-- {{customer.id}} -->
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col">
                        <h5>ORDER SUMMARY</h5>
                        <hr>
                        <table class="table table-borderless table-striped">
                            <thead>
                                <tr>
                                    <th>SN#</th>
                                    <th>Item</th>
                                    <th>Qty</th>
                                    <th class="text-end">Unit Price</th>
                                    <th class="text-end">Discount</th>
                                    <th class="text-end">Final Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in products" v-bind:key="index">
                                    <td>{{index+1}}</td>
                                    <td>{{item.name}}</td>
                                    <td>{{item.qty}}</td>
                                    <td class="text-end">{{item.price}} BDT</td>
                                    <td class="text-end">{{item.total_discount}} BDT</td>
                                    <td class="text-end">{{(item.price*item.qty)-item.total_discount}} BDT</td>
                                </tr>
                                
                            </tbody>
                        </table>
                        <hr>
                        <table class="table table-borderless small-font summary">                            
                            <tbody>
                                <tr>
                                    <td class="text-end">SUB TOTAL : {{price_summary.total}} BDT</td>
                                </tr>
                                <tr>
                                    <td class="text-end">VAT : {{price_summary.grand_total-price_summary.total}} BDT</td>
                                </tr>
                                <tr>
                                    <td class="text-end">LESS : {{price_summary.less}} BDT</td>
                                </tr>
                                <tr>
                                    <td class="text-end">TOTAL : {{price_summary.grand_total-price_summary.less}} BDT</td>
                                </tr>
                                <tr>
                                    <td class="text-end">PAID : {{price_summary.paid}} BDT</td>
                                </tr>
                                <tr>
                                    <td class="text-end">DUE : {{price_summary.due}} BDT</td>
                                </tr>                                
                            </tbody>
                        </table>
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col">
                        <h5>PAYMENT DETAILS</h5>
                        <hr>
                        <table class="table table-borderless table-striped">
                            <thead>
                                <tr>
                                    <th>SN#</th>
                                    <th>Type</th>
                                    <th class="text-end">Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in payment_summary" v-bind:key="index">
                                    <td>{{index+1}}</td>
                                    <td>{{item.type}}</td>
                                    <td class="text-end">{{item.amount}} BDT</td>
                                </tr>
                                
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <hr>
            <div class="card-body text-center"><small>If you have any query about this invoice, please contact administrator.</small></div>
        </div>
    </main>
</template>

<script>
    import DB from '../libraries/db.js';
    let db = new DB();

    export default {
        name: 'Invoice',
        components: {
        
        },
        props: ['orderNo', 'cart', 'summary', 'payments', 'customerNo'],
        data: () => {
            return{
                products: [],
                price_summary :'',
                payment_summary:'',
                // customer: {text: "Undefined", name: "Undefined", id: "xxxxxx", point: 0},
                shopId: 1,
                shopName: '',
                addressLine1: '',
                addressLine2: '',
                loggedInUser: '',
                showPreview: false
            }
            
        },
        computed: {

        },
        mounted() {
            let that = this;
            if(that.cart){
                that.products = JSON.parse(that.cart);
                that.price_summary = JSON.parse(that.summary);
                that.payment_summary = JSON.parse(that.payments);                
                // if(that.selectedCustomer != 'null'){
                //     that.customer = JSON.parse(that.selectedCustomer);
                // }

                db.getSettings(function(doc, error = false){
                    if(doc){
                        that.shopId = doc.outlet.id;
                        that.shopName = doc.outlet.name;
                        that.addressLine1 = doc.outlet.address;
                        that.addressLine2 = doc.outlet.address1;
                        that.loggedInUser = doc.email;
                        // that.showPreview = doc.showPreview;
                    }else{
                        that.$router.push({ path: 'login' })
                    }     
                });
            }else{
                // this.$router.push({'name': 'Main'});
            }
        },
        watch: {},
        methods: {
            print(){
                window.print();
            }
        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .invoice{
        font-size: 16px;
    }
    .invoice-logo{
        width: 100px;
    }
    .small-font{
        font-size: 12px;
        font-weight: bold;
    }
    table.summary td{
        padding-top: 1px;
        padding-bottom: 1px;
    }

    
</style>