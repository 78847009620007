<template>
  <main class="container">
    <div class="card">
      <div class="card-body">
        <h3>Settings</h3>
        <div class="alert alert-success" v-if="success">Updated successfully</div>
        <form action="" v-on:submit="update">
          <div class="mb-3">
            <label for="" class="form-label">Shop Name</label>
            <input type="text" class="form-control" v-model="shopName">            
          </div>
          <div class="mb-3">
            <label for="" class="form-label">Address Line 1</label>
            <input type="text" class="form-control" v-model="addressLine1">            
          </div>
          <div class="mb-3">
            <label for="" class="form-label">Address Line 2</label>
            <input type="text" class="form-control" v-model="addressLine2">            
          </div>
          <div class="mb-3">
            <label class="form-label">Logged in User</label>
            <input type="text" class="form-control" v-model="loggedInUser" readonly>
          </div>
          <div class="mb-3">
            <label class="form-label">Vat(%)</label>
            <input type="number" class="form-control" v-model="vat">
          </div>
          <!-- <div class="mb-3 form-check form-switch">
            <input class="form-check-input" type="checkbox" v-model="showPreview">
            <label class="form-check-label">Show Preview Before Completing Sale</label>
          </div> -->
          <button type="submit" class="btn btn-primary">Submit</button>
        </form>
      </div>
    </div>
  </main>
</template>

<script>
  import DB from '../libraries/db.js';
  let db = new DB();

  export default {
    name: 'Settings',
    components: {

    },
    props: {},
    data: () => {
      return {
        shopId: 1,
        shopName: '',
        addressLine1: '',
        addressLine2: '',
        loggedInUser: '',
        showPreview: false,
        doc: null,
        vat: 0,
        success: false
      }
    },
    computed: {

    },
    mounted() {
      console.log('mounted');
      let that = this;
      db.getSettings(function(doc, error = false){
        if(doc){
          // console.log(doc);
          that.doc = doc;
          that.vat = doc.vat;
          that.shopId = doc.outlet.id;
          that.shopName = doc.outlet.name;
          that.addressLine1 = doc.outlet.address;
          that.addressLine2 = doc.outlet.address1;
          that.loggedInUser = doc.email;
          // that.showPreview = doc.showPreview;
        }else{
          that.$router.push({ path: 'login' })
        }
      });
    },
    watch: {},
    methods: {
      update(e) {
          e.preventDefault();
          this.doc.outlet.name = this.shopName;
          this.doc.outlet.address = this.addressLine1;
          this.doc.outlet.address1 = this.addressLine2;
          this.doc.vat = this.vat;
          db.setSettings(this.doc);
          this.success = true;
      }
    }
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>