<template>
	<main class="container">

		<loading
			:show="isLoading"
			:label="loadingText">
		</loading>		

		<div class="starter-template">
			<div class="row">
				<div class="col-md-8">
					<!--
					<div class="scan-panel br">
						<div class="form-group row">
							<div class="col-sm-12">
								<form v-on:submit="addProductSubmit">
									<input type="text" class="form-control" id="serial" v-model="serial" autocomplete="off">
								</form>
							</div>
						</div>
					</div>
					-->
					<div class="scan-panel br">
						<div class="form-group row">
							<div class="col-sm-12">
								<form v-on:submit="addProductSubmit">
									<select2 :options="products" v-model="serial" @change="addProduct" ref="productList" class="select_two">
										<option disabled value="0" selected>Select one</option>
									</select2>
								</form>
							</div>
						</div>
					</div>
					<div class="item-panel table-responsive br">
						<table class="table table-borderless">
							<thead>
								<tr>
									<th>Delete</th>
									<th>Item</th>
									<th>Item Name</th>
									<th class="item-price">Price</th>
									<th class="item-quantity">Quantity</th>
									<th class="item-discount">Discount</th>
									<th>Total</th>
									<th>Refresh</th>
								</tr>
							</thead>
							<tbody>
								<template v-if="cart.length == 0">
									<tr>
										<td colspan="8">
											<div class="text-center" role="">
												No Item
											</div>
										</td>
									</tr>
								</template>
								<template v-else>
									<tr v-for="(item, index) in cart" v-bind:key="index">
										<td><a v-on:click="removeProduct(index)" class="text-danger remove-product"><i
													class="fas fa-minus-circle"></i></a></td>
										<td>{{item.serial}}</td>
										<td>{{item.name}} <br>
											{{item.optionDataInfo}}
										</td>
										<td><input type="number" min="1" step="1" class="form-control" name="price"
												v-model.number="item.price" v-on:change="processItem(index)"></td>
										<td><input type="number" min="1" :max="item.max" step="1" class="form-control"
												name="qty" v-model.number="item.qty" v-on:change="processItem(index)"></td>
										<td><input type="number" min="1" step="1" class="form-control" name="discount"
												v-model.number="cart[index].total_discount"
												v-on:change="discountCalculation(index)"></td>
										<td>{{item.total}}</td>
										<td><a v-on:click="processItem(index)" class="text-info refresh-product"><i
													class="fas fa-sync"></i></a></td>

									</tr>
								</template>
							</tbody>
						</table>
					</div>
				</div>
				<div class="col-md-4 right-panel br">
					
					<div class="footer text-right">
						<div class="float-start" v-if="online">
							<button type="button" class="btn btn-success" v-on:click="sync" :disabled="syncing">{{syncing?'loading...':'sync'}}</button>
						</div>
						<div class="alert success online" v-if="online">
							<div id="center-div">
								<div class="bubble">
									<span class="bubble-outer-dot">
									<span class="bubble-inner-dot"></span>
									</span>
								</div>
							</div>
						</div>
						<div class="alert danger" v-else>
							<div id="center-div">
								<div class="bubble">
									<span class="bubble-outer-dot">
									<span class="bubble-inner-dot"></span>
									</span>
								</div>
							</div>
						</div>
					</div>

					<div class="customer-panel">
						<p class="font-weight-bold">Customer Info:</p>
						<!-- <input type="text" class="form-control" id="" placeholder="Start typing customer mobile no..."> -->
						
						<div>
							<div v-if="customer_no == ''">
								<form v-on:submit="addNewCustomer">
									<input type="text" v-model="enter_customer_no" class="form-control" placeholder="Enter customer mobile no..">
								</form>
							</div>
							<div v-else>
								<div class="row">
									<div class="col text-start">Customer Mobile No:</div>
									<div class="col text-end">{{customer_no}} <a v-on:click="removeCustomer"
											class="text-danger remove-customer"><i class="fas fa-minus-circle"></i></a></div> 
								</div>
							</div>
						</div>

					</div>
					<hr>
					<div class="row">
						<div class="col text-start">Quantity of {{cart.length}} Item(s)</div>
						<div class="col text-end">{{summary.qty}}</div>
					</div>
					<div class="row">
						<div class="col text-start">Subtotal</div>
						<div class="col text-end">{{summary.sub_total}} BDT</div>
					</div>
					<div class="row">
						<div class="col text-start">Discount</div>
						<div class="col text-end">{{summary.discount}} BDT</div>
					</div>
					<div class="row">
						<div class="col text-start">Total</div>
						<div class="col text-end">{{summary.total}} BDT</div>
					</div>
					<div class="row">
						<div class="col text-start">Vat ({{vat}}%)</div>
						<div class="col text-end">{{grand_total-summary.total}} BDT</div>
					</div>
					<div class="row">
						<div class="col text-start">Grand Total</div>
						<div class="col text-end">{{grand_total}} BDT</div>
					</div>
					<hr>
					<div class="row">
						<div class="col text-start">Payments Total</div>
						<div class="col text-end">{{total_payment}} BDT</div>
					</div>
					<div class="row">
						<div class="col text-start">Amount Due</div>
						<div class="col text-end">{{amount_due}} BDT</div>
					</div>
					<div class="form-group row">
						<label for="" class="col-md-6 text-start">Discount:</label>
						<div class="col-md-6">
							<div v-if="less > 0">
								<div class="col text-end">{{less}} BDT <a v-on:click="less = 0" class="text-danger cursor-pointer"><i class="fas fa-pen"></i></a></div>
								
							</div>		
							<form v-else v-on:submit="setLess">					
								<div class="input-group">										
										<input type="number" class="form-control" v-model.number="less_value">										
										<div class="input-group-append">
											<span class="input-group-text">BDT</span>
										</div>								
								</div>
							</form>
						</div>
					</div>
					<hr>
					<div class="payment-panel">
						<form action="" v-on:submit="addPayment">
							<div class="form-group row">
								<label for="" class="col-sm-6 col-form-label text-end font-weight-bold">Payment
									Type:</label>
								<div class="col-sm-6">
									<select class="form-control" v-model="payment_type">
										<option value="1">Cash</option>
										<option value="2">Card</option>
										<option value="3">Bkash</option>
										<option value="7">Gift</option>
									</select>
								</div>
							</div>
							<div class="form-group row" v-if="payment_type == 2 || payment_type == 4">
								<label for="" class="col-sm-6 col-form-label text-end font-weight-bold">Bank
									Name:</label>
								<div class="col-sm-6">
									<select class="form-control" v-model="payment_bank">
										<option :value="item" v-for="(item, index) in banks" v-bind:key="index">{{item}}
										</option>
									</select>
								</div>
							</div>

							<div class="form-group row" v-if="payment_type == 2">
								<label for="" class="col-sm-6 col-form-label text-end font-weight-bold">Card No:</label>
								<div class="col-sm-6">
									<input type="text" name="" class="form-control" v-model="payment_card_no">
								</div>
							</div>

							<div class="form-group row" v-if="payment_type == 3">
								<label for="" class="col-sm-6 col-form-label text-end font-weight-bold">Bkash No:</label>
								<div class="col-sm-6">
									<input type="text" name="" class="form-control" v-model="payment_bkash_no">
								</div>
							</div>

							<!-- <div class="form-group row" v-if="payment_type == 4">
								<label for="" class="col-sm-6 col-form-label text-end font-weight-bold">Cheque
									No:</label>
								<div class="col-sm-6">
									<input type="text" name="" class="form-control" v-model="payment_cheque_no">
								</div>
							</div> -->
							
							
							

							<div class="form-group row">
								<label for="" class="col-sm-6 col-form-label text-end font-weight-bold">Amount
									Tendered:</label>
								<div class="col-sm-6">
									<input type="number" name="" class="form-control" v-model.number="payment_amount">
								</div>
							</div>
							<div class="form-group row">
								<label for="" class="col-sm-6 col-form-label text-end"></label>
								<div class="col-sm-6 d-grid">
									<button class="btn btn-block btn-success" type="submit"
										:disabled="payment_amount == 0 || (payment_type == 2 && payment_card_no == '') || (payment_type == 3 && payment_bkash_no == '')">Add
										Payment</button>
								</div>
							</div>
						</form>
						<div>
							<table class="table table-borderless">
								<thead>
									<tr>
										<th>Delete</th>
										<th>Type</th>
										<th>Amount</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(item, index) in payments" v-bind:key="index">
										<td><a v-on:click="removePayment(index)" class="text-danger"><i
													class="fas fa-minus-circle"></i></a></td>
										<td>{{item.type}}</td>
										<td>{{item.amount}} BDT</td>
									</tr>
								</tbody>
							</table>
						</div>
						<hr>
						<textarea class="form-control" placeholder="comment"></textarea>
						<hr>
						<div class="d-grid">
							<button v-on:click="complete" type="submit" class="btn btn-block btn-primary" name="" :disabled="disableCompleteButton">Complete</button>
						</div>
					</div>
				</div>
			</div>
		</div>

		

	</main>
</template>

<script>
	import select2 from '../components/AutoComplete.vue';
	import loading from 'vue-full-loading'
	import DB from '../libraries/db.js';
	let db = new DB();

	export default {
		name: 'Main',
		components: {
			select2,loading
		},
		props: {},
		data: () => {
			return {
				isLoading: false,
                loadingText: 'Loading...',
				products: [],
				customers: [],
				cart: [],
				serial: '',
				customer: 0,
				selectedCustomer: null,
				addCustomer: false,
				customer_no: '',
				enter_customer_no: '',
				name: null,
				mobile_no: null,
				summary: {
					'total': 0.00,
					'grand_total': 0.00,
					'qty': 0.00,
					'sub_total': 0.00,
					'discount': 0.00,
					'paid': 0.00,
					'due': 0.00,
					'less': 0.00
				},
				payments: [],
				payment_type: 1,
				payment_amount: 0,
				payment_bank: 'EBL',
				payment_cheque_no: '',
				payment_card_no: '',
				payment_bank: 0,
				less: 0,
				less_value: 0,



				banks: ['AB Bank Limited',
						'Bangladesh Commerce Bank Limited',
						'Bank Asia Limited',
						'BRAC Bank Limited',
						'Citizens Bank PLC',
						'City Bank Limited',
						'Community Bank Bangladesh Limited',
						'Dhaka Bank Limited',
						'Dutch-Bangla Bank Limited',
						'Eastern Bank Limited',
						'IFIC Bank Limited',
						'Jamuna Bank Limited',
						'Meghna Bank Limited',
						'Mercantile Bank Limited',
						'Midland Bank Limited',
						'Modhumoti Bank Limited',
						'Mutual Trust Bank Limited',
						'National Bank Limited',
						'National Credit & Commerce Bank Limited',
						'NRB Bank Limited',
						'NRB Commercial Bank Ltd',
						'One Bank Limited',
						'Padma Bank Limited',
						'Premier Bank Limited',
						'Prime Bank Limited',
						'Pubali Bank Limited',
						'Shimanto Bank Ltd',
						'Southeast Bank Limited',
						'South Bangla Agriculture and Commerce Bank Limited',
						'Trust Bank Limited',
						'United Commercial Bank Ltd',
						'Uttara Bank Limited',
						'Bengal Commercial Bank Ltd',
						'Al-Arafah Islami Bank Limited',
						'EXIM Bank Limited',
						'First Security Islami Bank Limited',
						'ICB Islamic Bank Limited',
						'Islami Bank Bangladesh Limited',
						'Shahjalal Islami Bank Limited',
						'Social Islami Bank Limited',
						'Union Bank Ltd',
						'Standard Bank Limited',
						'Other'
					   ],



				vat: 10,
				shopId: null,
				online: window.navigator.onLine,
				syncing: false,
				disableCompleteButton: false
			}
		},
		computed: {
			total_payment() {
				let total = 0;
				this.payments.forEach((item) => {
					total += parseFloat(item.amount);
				})
				return total;
			},
			amount_due() {
				return this.grand_total - this.total_payment - this.less;
			},
			grand_total(){
				return this.summary.total+(this.summary.total*this.vat/100);
			},
		},
		mounted() {
			let that = this;
			setInterval(function(){
				that.online = window.navigator.onLine;
			}, 1000);
			db.getSettings(function (doc) {
				if (doc) {
					// console.log(doc);
					db.setOutletId(doc.outlet.id);
					db.setCompanyId(doc.company_id);
					db.setUserKey(doc.user_key);
					db.setVat(doc.vat);
					that.vat = doc.vat;
					that.shopId = doc.outlet.id;
					that.shopName = doc.outlet.name;
					that.loggedInUser = doc.email;
					// that.showPreview = doc.showPreview;
					that.sync();
					setInterval(function(){
						// that.sync();
						db.syncOrders();
					}, 120000);
				}else{
					that.$router.push({ path: 'login' })
				}
			});


		},
		watch: {},
		methods: {
			sync(){
				// console.log('syncing');
				let that = this;
				that.syncing = true;
				db.syncOrders();
				db.syncProducts(function(){
					that.getProductList();
					that.syncing = false;
				});
			},
			grandTotal() {
				let total = 0;
				let sub_total = 0;
				let discount = 0;
				let qty = 0;
				this.cart.forEach((item) => {
					sub_total += item.sub_total;
					discount += parseFloat(item.total_discount);
					total += item.total;
					qty += parseInt(item.qty);
				});
				this.summary = {
					'total': total,
					'qty': qty,
					'sub_total': sub_total,
					'discount': discount,
					'paid': this.total_payment,
					'due': this.amount_due,
					'less': this.less,
					'grand_total': this.grand_total
				};
			},
			getProductList(callback) {
				db.getProducts((products) => {
					this.products = products;
					// console.log(this.products);
					if (typeof callback == 'function') callback();
				});
			},
			getCustomerList() {
				
			},
			addProduct(serial) {
				if (serial == '') return false;
				let product = this.getProductBySerial(serial);
				let cartIndex = this.getCartIndex(serial);

				setTimeout(() => {

					if (cartIndex !== false) {
						this.cart[cartIndex].qty += product.qty;
						this.processItem(cartIndex);
					} else {
						this.cart.push(product);
						this.processItem(this.cart.length - 1);
					}
					
					$('.select_two').select2('open');
					//document.getElementById("serial").focus();
					// this.$refs.productList.open();

				}, 100);
			},
			addProductSubmit(e) {
				e.preventDefault();
				if (this.serial == '') return false;
				let product = this.getProductBySerial(this.serial);
				if(!product) return false;
				let cartIndex = this.getCartIndex(this.serial);

				setTimeout(() => {

					if (cartIndex !== false) {
						this.cart[cartIndex].qty += product.qty;
						this.processItem(cartIndex);
					} else {
						this.cart.push(product);
						this.processItem(this.cart.length - 1);
					}
					this.serial = '';

					$('.select_two').select2('open');
					//document.getElementById("serial").focus();
					// this.$refs.productList.open();

				}, 100);
			},
			getCartIndex(serial) {
				for (let i = 0; i < this.cart.length; i++) {
					if (this.cart[i].serial == serial) {
						return i;
						break;
					}
				}
				return false;
			},
			processItem(index) {
				if (this.cart[index].qty > this.cart[index].max) this.cart[index].qty = this.cart[index].max;
				this.cart[index].total_discount = this.cart[index].discount * this.cart[index].qty;
				this.cart[index].sub_total = (this.cart[index].price * this.cart[index].qty);
				this.cart[index].total = (this.cart[index].price * this.cart[index].qty) - this.cart[index].total_discount;
				this.grandTotal();
			},
			discountCalculation(index) {
				this.cart[index].sub_total = (this.cart[index].price * this.cart[index].qty);
				this.cart[index].total = (this.cart[index].price * this.cart[index].qty) - this.cart[index].total_discount;
				this.grandTotal();
			},
			getProductBySerial(serial) {
				const product = this.products.find(element => element.id == serial);
				if(product == null) {
					this.$toast.error(`Invalid serial no`);
					return false;
				}
				return {
					'serial': serial,
					'name': product.name,
					'price': product.price,
					'product_id': product.product_id,
					'discount': 0,
					'qty': 1,
					'total_discount': 0,
					'total': 0,
					'sub_total': 0,
					'max': product.remaining_qty,
					'optionData': product.optionData,
					'optionDataInfo': product.optionDataInfo
				};
			},
			removeProduct(index) {
				this.cart.splice(index, 1);
				this.grandTotal();
			},
			addPayment(e) {
				e.preventDefault();
				if(this.total_payment+this.payment_amount > this.grand_total) {
					this.$toast.error(`Please check amount`);
					return false;
				}
				if (this.payment_type == 1) this.payments.push({
					type: 'Cash',
					amount: this.payment_amount
				});
				else if (this.payment_type == 2) this.payments.push({
					type: 'Card',
					amount: this.payment_amount,
					cheque_no: this.payment_card_no
				});
				else if (this.payment_type == 7) this.payments.push({
					type: 'Gift',
					amount: this.payment_amount
				});
				else this.payments.push({
					type: 'Bkash',
					amount: this.payment_amount,
					card_no: this.payment_bkash_no
				});
				this.payment_type = 1;
				this.payment_amount = 0;
				this.payment_cheque_no = '';
				this.payment_card_no = '';
				this.payment_bkash_no = '';
			},
			removePayment(index) {
				this.payments.splice(index, 1);
			},
			selectCustomer(customer) {
				// if (customer == 0) {
				// 	setTimeout(() => {
				// 		this.addCustomer = true;
				// 	}, 100);
				// 	return false;
				// }
				// for (let i = 0; i < this.customers.length; i++) {
				// 	if (this.customers[i].id == customer) {
				// 		setTimeout(() => {
				// 			this.selectedCustomer = this.customers[i];
				// 		}, 100);
				// 		break;
				// 	}
				// }
			},
			customerExists(mobile_no) {
				for (let i = 0; i < this.customers.length; i++) {
					if (this.customers[i].id == mobile_no) {
						return true;
					}
				}
			},
			addNewCustomer(e) {
				e.preventDefault();
				this.customer_no = this.enter_customer_no;
				this.enter_customer_no = '';
			},
			removeCustomer() {
				this.customer_no = '';
			},
			setLess(e){
				e.preventDefault();
				this.less = 0;
				if(this.less_value) this.less = this.less_value;
				
			},
			complete(){
				let that = this;
				if(that.amount_due > 0){
					this.$toast.error(`You have due amount`);
					return false;
				}
				if(this.grand_total == 0){
					this.$toast.error(`No product selected or amount is zero`);
					return false;
				}
				if(this.customer_no == ''){
					this.$toast.error(`Please enter customer mobile no`);
					return false;
				}
				that.disableCompleteButton = true;
				this.grandTotal();
				let orderNo = 'VS'+new Date().getTime()+that.shopId;
				// that.customer_no = {id: 1, first_name: 'A', last_name: 'B', phone_number: '0', shipping_address: 'Dhaka'};
				db.order({outlet_id: that.shopId, order_no: orderNo, customer: that.customer_no, cart: that.cart, summary: that.summary, payments: that.payments}, (res) => {
					if(res){
						db.syncLocalProductDB(that.cart);
						that.$router.push({ name: 'Invoice', params: { 
							orderNo: orderNo,
							cart: JSON.stringify(that.cart),
							summary: JSON.stringify(that.summary),
							payments: JSON.stringify(that.payments),
							customerNo: that.customer_no
						}})
					}
					
				});
			}
		}
	};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	.remove-product,
	.refresh-product {
		cursor: pointer;
	}
	.cursor-pointer{
		cursor: pointer;
	}
	.online{
		display: flow-root;
	}
</style>