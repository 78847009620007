<template>
  <main class="container">
    <div class="row mt-5">
      <div class="col-md-6 offset-md-3">
        <div class="card">
          <div class="card-body">
            <div class="text-center">
              <h4>Login to the system</h4>
              <hr>
            </div>
            
            <div class="alert alert-danger" v-if="error">Invalid credentials</div>
            <form action="" v-on:submit="login">
              <div class="mb-3">
                <label for="" class="form-label">Email</label>
                <input type="email" class="form-control" v-model="email">
              </div>
              <div class="mb-3">
                <label for="" class="form-label">Password</label>
                <input type="password" class="form-control" v-model="password">
              </div>
              <button type="submit" class="btn btn-primary" :disabled="email == '' || password == '' || disabled">{{buttonText}}</button>
            </form>
          </div>
        </div>
      </div>
    </div>

  </main>
</template>

<script>
  import DB from '../libraries/db.js';
  let db = new DB();

  export default {
    name: 'Login',
    components: {

    },
    props: {},
    data: () => {
      return {
        email: '',
        password: '',
        buttonText: 'Login',
        disabled: false,
        error: false
      }
    },
    computed: {

    },
    mounted() {
      // console.log('mounted');
    },
    watch: {},
    methods: {
      login(e) {
        e.preventDefault();
        let that = this;
        that.disabled = true;
        that.buttonText = 'Checking...';
        db.login({
          'email': this.email,
          'password': this.password
        }, (res) => {

          that.disabled = false;
          that.buttonText = 'Login';

          if (res) {
            that.$router.push({
              path: '/'
            })
          } else {
            that.error = true;
            setTimeout(function(){
              that.error = false;
            }, 5000);
          }
        });
      }
    }
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>