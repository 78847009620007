<template>
	<nav class="navbar navbar-expand-lg navbar-dark bg-dark fixed-top">
		<div class="container">
			<router-link class="navbar-brand" to="/"><img class="logo" src="../assets/logo.png" alt=""></router-link>
			<button class="navbar-toggler" type="button" data-toggle="collapse" data-bs-target="#navbarToogle"
				aria-controls="navbarToogle" aria-expanded="false" aria-label="Toggle navigation">
				<span class="navbar-toggler-icon"></span>
			</button>
			<div class="collapse navbar-collapse" id="navbarToogle">
				<div class="navbar-nav me-auto"></div>
				<ul class="navbar-nav">
					<li class="nav-item active">
						<router-link class="nav-link" to="/">Sale</router-link>
					</li>
					<!-- <li class="nav-item">
						<a class="nav-link" href="#">Link</a>
					</li>
					<li class="nav-item">
						<a class="nav-link disabled" href="#">Disabled</a>
					</li> -->
					<li class="nav-item dropdown">
						<a class="nav-link dropdown-toggle" href="http://example.com" id="dropdown01"
							data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Accounts</a>
						<div class="dropdown-menu" aria-labelledby="dropdown01">
							<router-link class="dropdown-item" to="/settings">Settings</router-link>
							<a class="dropdown-item" href="#" v-on:click="logout">Logout</a>
						</div>
					</li>
				</ul>

			</div>
		</div>
	</nav>
</template>


<script>
	import DB from '../libraries/db.js';
	let db = new DB();
	export default {
		name: 'NavBar',
		props: {},
		mounted(){
			
		},
		methods: {
			logout() {
				let that = this;
				db.setSettings({});
				that.$router.push({
					path: 'login'
				});
			}
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>