import { createApp } from 'vue'

// import 'bootstrap/dist/css/bootstrap.min.css'
// import 'select2/dist/css/select2.min.css'
import './assets/css/style.css'

// import 'jquery/src/jquery.js'
// import 'bootstrap/dist/js/bootstrap.min.js'

// import '@fortawesome/fontawesome-free/css/all.css'
// import '@fortawesome/fontawesome-free/js/all.js'

import App from './App.vue'
import router from './router'

import Toaster from '@meforma/vue-toaster';

const app = createApp(App);

app.use(router);
app.use(Toaster);
app.mount('#app');

// createApp(App).mount('#app')
