// let PouchDB = require('pouchdb').default;
import PouchDB from 'pouchdb-browser';

let productDb = new PouchDB('vapesoft_pos_products');
let customerDb = new PouchDB('vapesoft_pos_customers');
let settingsDb = new PouchDB('vapesoft_pos_settings');
let ordersDb = new PouchDB('vapesoft_pos_orders');
let apiBaseUrl = 'https://admin.vapesoft.xyz/api/';
// let apiBaseUrl = 'http://admin.vapesoft.xyz/api/';
// let apiBaseUrl = 'http://localhost/vapesoft_inventory_update/public/api/';
const axios = require('axios');
class DB {
    constructor() {
        this.outlet_id = null;
        this.company_id = null;
        this.user_key = null;
        this.vat = 0;
    }
    setOutletId(id){
        this.outlet_id = id;
    }
    setCompanyId(id){
        this.company_id = id;
    }
    setUserKey(user_key){
        this.user_key = user_key;
    }
    setVat(vat){
        this.vat = vat;
    }
    isOnline(){
        // return false;
        return window.navigator.onLine;
    }

    login(data, callback = null){
        let that = this;
        this.postRemoteData('login', data, (res) => {
            // console.log(res);
            if(res.status){
                res.data.vat = 10;
                that.setSettings(res.data);
                if(typeof callback == 'function'){
                    callback(true);
                }
            }else{
                if(typeof callback == 'function'){
                    callback(false);
                }  
            }
        });
    }

    processItems(element){
        // console.log(element);
        let optionData = JSON.parse(element.option_data);
        if(element.product == null) return false;
        if(element.product.brand == null) return false;
        let optionDataInfo = '';
        if(optionData.length > 0){
            optionDataInfo += ' (';
            for (let i = 0; i < optionData.length; ++i) {
                optionDataInfo += optionData[i].option_name+':'+optionData[i].option_value;
                if(i != (optionData.length - 1)){
                    optionDataInfo += '|';
                }
            }
            optionDataInfo += ') ';
        }
        let item = {
            id:element.serial_number,
            product_id:parseInt(element.product.id),
            text:element.product.name+' ('+element.product.brand.name+')'+optionDataInfo+' # '+element.serial_number,
            name: element.product.name,
            price: (element.product.discount_price != null && element.product.discount_price != 0)?element.product.discount_price:element.product.sell_price,
            optionData: optionData[0],
            optionDataInfo: optionDataInfo,
            remaining_qty: element.remaining_qty
        };
        return item;
    }
    getProducts(callback = null) {
        // console.log('getting product list');
        let products = [];
        let that = this;
        this.getLocalData(productDb, (data) => {
            if(data.length > 0){
                // console.log('found local products');
                data.forEach(element => {
                    let item = that.processItems(element.doc);
                    if(item != false) products.push(item)
                });
                callback(products)
            }else{
                // console.log('getting remote products');
                that.syncProducts(callback);
            }
            
        });
        
    }
    syncProducts(callback = null) {
        // console.log('synching products');
        let that = this;
        let products = [];
        if(that.isOnline()){
            // console.log('we are online');
            that.postRemoteData('stock', {'outlet_id' : that.outlet_id, 'company_id' : that.company_id, 'user_key' : that.user_key}, (data) => {
                // console.log(data);
                data.data.forEach(element => {
                    let item = that.processItems(element);
                    if(item) products.push(item)
                });
                if(typeof callback == 'function'){
                    callback(products);
                }
                productDb.destroy().then(function () {
                    productDb = new PouchDB('vapesoft_pos_products');
                    productDb.bulkDocs(data.data).then(function (data) {
                        // console.log('local record updated');
                    }).catch(function (err) {
                        // console.log(err);
                    });
                });
                
            });

        }
    }
    syncLocalProductDB(cartData, callback = null) {
        let that = this;
        if(cartData.length > 0){
            let products = [];
            let cartProduct = [];
            let cartSerials = [];

            cartData.forEach(cartElement => {
                cartProduct[cartElement.serial] = cartElement.qty;
                cartSerials.push(cartElement.serial);
            });

            this.getLocalData(productDb, (data) => {
                if(data.length > 0){
                    data.forEach(element => {
                        let item = element.doc;
                        if(item != false){
                            let item_remaining_qty = item.remaining_qty;
                            if(jQuery.inArray(item.serial_number, cartSerials) !== -1){
                                item_remaining_qty = (item.remaining_qty - cartProduct[cartSerials]);
                            }
                            if(item_remaining_qty > 0){
                                item.remaining_qty = item_remaining_qty;
                                delete item['_id'];
                                delete item['_rev'];
                                products.push(item);
                            }
                        }
                    });
                    productDb.destroy().then(function () {
                        productDb = new PouchDB('vapesoft_pos_products');
                        productDb.bulkDocs(products).then(function (data) {
                            // console.log('local record updated');
                        }).catch(function (err) {
                            // console.log(err);
                        });
                    });
                }            
            });
        }
    }
    order(data, callback = null){
        // console.log(data);
        let that = this;
        // console.log(data);
        let postData = {
            outlet_id: data.outlet_id,
            order_no: data.order_no, 
            vat: that.vat,
            customer_id: null,
            first_name: 'pos customer', 
            last_name: null, 
            phone_number: data.customer, 
            shipping_address: null, 
            total_amount: data.summary.grand_total, 
            less_amount: data.summary.less, 
            products: data.cart,
            payments: data.payments
        };
        if(this.isOnline()){
            // console.log('posting remote');
            this.postRemoteData('order', {'orders' : [postData], 'company_id' : that.company_id, 'user_key' : that.user_key}, (res) => {
                if(res.status == true){
                    if(typeof callback == 'function'){
                        callback(true);
                    } 
                }else{
                    if(typeof callback == 'function'){
                        callback(false);
                    } 
                }
            });
        }else{
            // console.log('saving local');
            postData._id = postData.order_no;
            ordersDb.put(postData, function(err, response) {
                // console.log(err);
                if (err) {
                    if(typeof callback == 'function'){
                        callback(false);
                    } 
                } else {
                    if(typeof callback == 'function'){
                        // this.syncLocalProducts(data.cart);
                        callback(true);
                    } 
                }
             });
        }
        
    }

    syncOrders(){
        // console.log('syncing orders');
        let that = this;
        if(this.isOnline()){
            this.getLocalData(ordersDb, (res) => {
                let postData = [];
                res.forEach((item)   => {
                    postData.push(item.doc);
                })
                this.postRemoteData('order', {'orders' : postData, 'company_id' : that.company_id, 'user_key' : that.user_key}, (res) => {
                    if(res.status == true){
                        ordersDb.destroy().then(function () {
                            ordersDb = new PouchDB('vapesoft_pos_orders');                            
                        });
                        if(typeof callback == 'function'){
                            callback(true);
                        } 
                    }else{
                        if(typeof callback == 'function'){
                            callback(false);
                        } 
                    }
                });
            });
        }
        
    }
    
    getSettings(callback){
        settingsDb.get('vapesoft_settings').then(function (doc) {
            // handle doc
            callback(doc);
        }).catch(function (err) {
            callback(false, err);
        });
    }

    setSettings(doc){
        let data = doc;
        data._id = "vapesoft_settings";
        data._rev = "vapesoft_settings";
        settingsDb.put(data, {_deleted: true, force: true}).then(function (doc) {
            // console.log('settings updated');
        }).catch(function (err) {
            // console.log(err);
        });
    }

    getRemoteData(url, data = {}, callback){
        // console.log('fetching remote data');
        axios.get(apiBaseUrl+url, { params: data })
        .then(function (response) {
            if(typeof callback == 'function'){
                callback(response.data);
            } 
        })
        .catch(function (error) {
            // handle error
            // console.log(error);
        })
        .then(function () {
            // always executed
        });
    }
    
    postRemoteData(url, data = {}, callback = null){
        // console.log('posting remote data');
        axios.post(apiBaseUrl+url, data)
        .then(function (response) {
            if(typeof callback == 'function'){
                callback(response.data);
            }            
        })
        .catch(function (error) {
            if(typeof callback == 'function'){
                callback(false);
            }
        })
        .then(function () {
            // always executed
        });
    }
    getLocalData(db, callback){
        db.allDocs({
            include_docs: true,
            attachments: true
        }).then(function (data) {
            callback(data.rows);
        }).catch(function (err) {
            // console.log(err);
        });
    }
}


export default DB;